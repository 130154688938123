.footer {
  padding: 10px 10px;
  display: flex;
  justify-content: space-around;
  font-size: 20px;
  background-color: rgb(214, 197, 255); 
  margin-top: 24px;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 720px) {
  .footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
}

