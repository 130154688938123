.testimonial-card {
    padding: 8px;
  
    width: 45%;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }
  .testimonial-image {
    height: 100px;
    border-radius: 50%
  }
  .testimonial-info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .testimonial-desc {
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: horizontal;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
  }
  @media only screen and (max-width: 720px) {
    .testimonial-card {
      width: 100%;
    }
  }
  