.projects {
  display: flex;
  flex-direction: column;

  flex: 1;
}

/* .project-img {
  border-radius: 20px;
  border: 5px solid rgb(192, 221, 88);
} */