.projectseparator {
    background-image: linear-gradient(
      to right top,
      #111113,
      #5c52e9
      
    );
    height: 3px;
    width: 90%;
    border-radius: 3px;
    margin: 10px 0px;
  }
  