.about {
 /* background-color: rgba(255, 255, 255, 0.6); for background color*/
  padding-bottom: 48px;
}
.about-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.picture {
  height: 420px;
}
.about-info {
  font-size: 24px;
}
.info-name {
  font-size: 32px;
  font-weight: 700;

}
.green{
  color: #048354;
}
.red{
  color: #fc2015;
}
.blue{
   color: #2077e9;
}
.yellow{
 color:gold; 
}
@media only screen and (max-width: 720px) {
  .about-top {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .picture {
    height: 280px;
  }
  .about-info {
    font-size: 20px;
  }
  .info-name {
    font-size: 26px;
  }
}
